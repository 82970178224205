import { sprintf, _nx } from '@wordpress/i18n';
import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const popularRequestWrappers = document.querySelectorAll(
    '.search-popular-requests'
  );
  /* popular requests init search on click */
  const requestBtns = document.querySelectorAll('.findstr-search-request');

  if (0 < popularRequestWrappers.length) {
    popularRequestWrappers.forEach((popularRequests) => {
      new Swiper(
        popularRequests.querySelector('.search-popular-requests__carousel'),
        {
          slidesPerView: 'auto',
          spaceBetween: 11,
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
        }
      );
    });
  }

  if (0 < requestBtns.length) {
    requestBtns.forEach(function (requestBtn) {
      requestBtn.addEventListener('click', function () {
        const group = requestBtn.dataset.findstrGroup;
        const term = requestBtn.dataset.searchWord;

        const input = document.querySelector(
          `#${group}_hero-search[data-findstr-group="${group}"]`
        );
        input.value = term;
        const keyEvent = new KeyboardEvent('input', {
          bubbles: true,
          target: input,
        });
        input.dispatchEvent(keyEvent);
      });
    });
  }
});

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;

  if (findstr) {
    findstr.hooks.addAction(
      'searchResults',
      'global-search',
      (results, group) => {
        if ('global-search' === group || 'health-guide' === group) {
          const titleHTML = document.querySelector(
            '.global-search__results__heading__title'
          );
          titleHTML.innerHTML = sprintf(
            /* translators: %1$s is number of results, %2$s is the term searched by the user */
            _nx(
              '%1$s result found for "%2$s"',
              '%1$s results found for "%2$s"',
              results.totalHits,
              'search results page title',
              'vtx'
            ),
            '<span class="results-count">' + results.totalHits + '</span>',
            '<span class="searched-term">' + results.query + '</span>'
          );
        }
      }
    );

    findstr.hooks.addFilter(
      'findstrCheckboxFieldChoices',
      'findstr-search',
      (choices, field) => {
        if (field.slug === 'search-types') {
          // Define the order
          const orderedTypes = [
            'Cliniques',
            'Services',
            'Experts',
            'Pages',
            'Guide de santé',
            'Nouvelles',
          ];

          // Create a new object with properties in the desired order
          const sortedChoices = {};
          orderedTypes.forEach((type) => {
            if (choices[type]) {
              sortedChoices[type] = choices[type];
            }
          });

          return sortedChoices;
        }

        return choices;
      }
    );

    findstr.hooks.addFilter(
      'findstrCheckboxFieldChoices',
      'findstr-search',
      (choices, field) => {
        if (field.slug === 'search-types') {
          // Previous code for search-types
        }

        if (field.slug === 'health-guide-categories') {
          // Define the order you want for health-guide-categories using corresponding titles
          const orderedTitles = [
            'Santé sportive',
            'Mode de vie actif',
            'Blessures et douleurs',
            'Réadaptation',
          ];

          // Create a new object with properties in the desired order
          const sortedChoices = {};
          orderedTitles.forEach((title) => {
            // Find the key in choices that matches the title
            for (const key in choices) {
              if (choices[key].label === title) {
                sortedChoices[key] = choices[key];
              }
            }
          });

          return sortedChoices;
        }

        return choices;
      }
    );

    findstr.hooks.addFilter(
      'urlManagementDisabled',
      'findstr-search',
      (disabled, group) => {
        const disabledGroups = [
          'hero-search',
          'health-guide-hero-search',
          'service-experts',
          'service-clinics',
          'modal-search',
        ];
        if (disabledGroups.includes(group)) {
          disabled = true;
        }
        return disabled;
      }
    );
  }
});
